<template>
  <v-app>
    <v-container v-if="isVerified" class="bg-white h-100 py-0">
      <v-row>
        <v-col v-if="isSubmitted" md="12" class="px-10 py-0">
          <div class="quotation-approved my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your approval against package #
                {{ quotation_detail.quotation.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else-if="isRejected" md="12" class="px-10 py-0">
          <div class="quotation-approved rejected my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f36161;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">&#10006;</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your rejection against package #
                {{ quotation_detail.quotation.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else md="12" class="px-10 py-0">
          <table width="100%">
            <thead>
              <tr>
                <td
                  colspan="10"
                  align="center"
                  class="custom-customer-border-bottom-2px"
                >
                  <v-img
                    contain
                    lazy-src="https://fakeimg.pl/745x135"
                    max-height="135"
                    max-width="745"
                    :src="header_footer"
                  ></v-img>
                </td>
              </tr>
              <tr>
                <td height="50px" colspan="10" align="center" valign="center">
                  <h1 class="quotation">PACKAGE</h1>
                </td>
              </tr>
              <tr>
                <td width="70px" height="30px" valign="center">Our Ref:</td>
                <td height="30px" colspan="9" valign="center">
                  {{ quotation_detail.quotation.barcode }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="30px" valign="center">
                  {{ formatDate(quotation_detail.quotation.date) }}
                </td>
              </tr>
            </thead>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td
                  colspan="10"
                  height="30px"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ quotation_detail.customer.company_name }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="20px" valign="center">
                  {{ quotation_detail.billing.unit_no }},
                  {{ quotation_detail.billing.street_1 }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="20px" valign="center">
                  {{ quotation_detail.billing.street_2 }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="20px" valign="center">
                  <template v-if="quotation_detail.billing.country">
                    {{ quotation_detail.billing.country.name }}
                  </template>
                  {{ quotation_detail.billing.zip_code }}
                </td>
              </tr>
              <tr>
                <td width="70px" height="30px" valign="center">Attn:</td>
                <td height="30px" colspan="4" valign="center">
                  {{ quotation_detail.contact_person.display_name }}
                </td>
                <td width="100px" height="30px" valign="center">
                  Contact no.:
                </td>
                <td height="30px" colspan="4" valign="center">
                  {{ quotation_detail.contact_person.primary_phone }}
                </td>
              </tr>
              <tr>
                <td width="70px" height="30px" valign="center">Email:</td>
                <td height="30px" colspan="9" valign="center">
                  {{ quotation_detail.contact_person.primary_email }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  height="30px"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ quotation_detail.quotation.quotation_title }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="30px" valign="center">
                  <div
                    class="pre-formatted"
                    v-html="quotation_detail.quotation.description"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table
            v-if="quotation_detail.packages.length"
            width="100%"
            class="package-approval-line-items"
          >
            <thead>
              <tr>
                <th height="40px" width="60%" class="pl-4">
                  <p class="m-0 py-1 px-2 custom-grey-border">
                    Package ({{ quotation_detail.packages.length }})
                  </p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Quantity</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Rate</p>
                </th>
                <th
                  v-if="false"
                  height="40px"
                  width="10%"
                  class=""
                  align="right"
                >
                  <p class="m-0 py-1 px-2 custom-grey-border">Optional</p>
                </th>
                <th height="40px" width="10%" class="pr-4" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Total</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in quotation_detail.packages">
                <tr :key="index" :class="{ 'is-strike': row.strike }">
                  <td height="40px" width="60%" class="pl-4">
                    <v-layout class="custom-grey-border">
                      <v-flex
                        class="text-center mx-2 py-2"
                        v-if="row.product_image"
                      >
                        <v-avatar size="80">
                          <v-img contain :src="row.product_image"></v-img>
                        </v-avatar>
                      </v-flex>
                      <v-flex class="px-2 custom-border-left" md12>
                        <v-layout class="my-1">
                          <v-flex>
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="orange"
                                >
                                  {{ row.product_type }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize">Type</span>
                            </v-tooltip>
                          </v-flex>
                          <v-flex class="mx-2">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ row.barcode }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize"
                                >{{ row.product_type }} #</span
                              >
                            </v-tooltip>
                          </v-flex>
                          <v-flex md10 class="font-weight-500">{{
                            row.product
                          }}</v-flex>
                        </v-layout>
                        <p class="py-1 font-weight-400 custom-border-top">
                          {{ row.description }}
                        </p>
                        <template v-if="row.is_optional">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="orange"
                              >
                                Optional
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >Marked as Optional</span
                            >
                          </v-tooltip>
                        </template>
                      </v-flex>
                    </v-layout>
                    <br />
                    <template v-if="row.strike">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="custom-border-bottom"
                          ></span>
                        </template>
                        <span class="text-capitalize"
                          >Package {{ row.product }} is removed.</span
                        >
                      </v-tooltip>
                    </template>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      {{ formatNumber(row.quantity) }}
                    </p>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      <template v-if="row.is_optional == 0">{{
                        formatMoney(row.rate)
                      }}</template>
                      <template v-else>{{ formatMoney() }}</template>
                    </p>
                  </td>
                  <td v-if="false" valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      <template v-if="row.is_optional == 1">{{
                        formatMoney(row.rate)
                      }}</template>
                      <template v-else>{{ formatMoney() }}</template>
                    </p>
                  </td>
                  <td valign="top" width="10%" align="right" class="pr-4">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      {{ formatMoney(row.total) }}
                    </p>
                  </td>
                </tr>
                <tr
                  v-for="(line_item, dindex) in row.line_items"
                  :key="`package-item-${index}-${dindex}`"
                  :class="{ 'is-strike': line_item.strike }"
                >
                  <td height="40px" width="60%" class="pl-4">
                    <v-layout class="custom-grey-border">
                      <v-flex
                        class="text-center mx-2 py-2"
                        v-if="line_item.product_image"
                      >
                        <v-avatar size="80">
                          <v-img contain :src="line_item.product_image"></v-img>
                        </v-avatar>
                      </v-flex>
                      <v-flex class="px-2 custom-border-left" md12>
                        <v-layout class="my-1">
                          <v-flex>
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ line_item.product_type }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize">Type</span>
                            </v-tooltip>
                          </v-flex>
                          <v-flex class="mx-2">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="orange"
                                >
                                  {{ row.barcode }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize"
                                >{{ row.product_type }} #</span
                              >
                            </v-tooltip>
                          </v-flex>
                          <v-flex class="mr-2">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ line_item.barcode }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize"
                                >{{ line_item.product_type }} #</span
                              >
                            </v-tooltip>
                          </v-flex>
                          <v-flex md10 class="font-weight-500">{{
                            line_item.product
                          }}</v-flex>
                        </v-layout>
                        <p class="py-1 font-weight-400 custom-border-top mb-1">
                          {{ line_item.description }}
                        </p>
                        <template v-if="line_item.is_optional">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status font-weight-600 custom-grey-border text-uppercase mb-1"
                                text-color="white"
                                color="orange"
                              >
                                Optional
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >Marked as Optional</span
                            >
                          </v-tooltip>
                        </template>
                      </v-flex>
                    </v-layout>
                    <br />
                    <template v-if="line_item.strike">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="custom-border-bottom"
                          ></span>
                        </template>
                        <span class="text-capitalize"
                          >Service {{ line_item.product }} is removed.</span
                        >
                      </v-tooltip>
                    </template>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <p
                      class="px-2 font-weight-600 text-left custom-grey-border py-1"
                    >
                      {{ formatNumber(line_item.quantity) }}
                    </p>
                  </td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="10%" align="right" class="pr-4"></td>
                </tr>
              </template>
            </tbody>
          </table>
          <table
            v-if="quotation_detail.services.length"
            width="100%"
            class="package-approval-line-items"
          >
            <thead>
              <tr>
                <th height="40px" width="60%" class="pl-4">
                  <p class="m-0 py-1 px-2 custom-grey-border">
                    Service ({{ quotation_detail.services.length }})
                  </p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Quantity</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Rate</p>
                </th>
                <th height="40px" width="10%" class="" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Optional</p>
                </th>
                <th height="40px" width="10%" class="pr-4" align="right">
                  <p class="m-0 py-1 px-2 custom-grey-border">Total</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in quotation_detail.services"
                :key="index"
                :class="{ 'is-strike': row.strike }"
              >
                <td height="40px" width="60%" class="pl-4">
                  <v-layout class="custom-grey-border">
                    <v-flex
                      class="text-center mx-2 py-2"
                      v-if="row.product_image"
                    >
                      <v-avatar size="80">
                        <v-img contain :src="row.product_image"></v-img>
                      </v-avatar>
                    </v-flex>
                    <v-flex class="px-2 custom-border-left" md12>
                      <v-layout class="my-1">
                        <v-flex>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="cyan"
                              >
                                {{ row.product_type }}
                              </v-chip>
                            </template>
                            <span class="text-capitalize">Type</span>
                          </v-tooltip>
                        </v-flex>
                        <v-flex class="mx-2">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="cyan"
                              >
                                {{ row.barcode }}
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >{{ row.product_type }} #</span
                            >
                          </v-tooltip>
                        </v-flex>
                        <v-flex md10 class="font-weight-500">{{
                          row.product
                        }}</v-flex>
                      </v-layout>
                      <p class="py-1 font-weight-400 custom-border-top mb-1">
                        {{ row.description }}
                      </p>
                      <template v-if="row.is_optional">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="custom-status font-weight-600 custom-grey-border text-uppercase mb-1"
                              text-color="white"
                              color="orange"
                            >
                              Optional
                            </v-chip>
                          </template>
                          <span class="text-capitalize"
                            >Marked as Optional</span
                          >
                        </v-tooltip>
                      </template>
                    </v-flex>
                  </v-layout>
                  <br />
                  <template v-if="row.strike">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="custom-border-bottom"
                        ></span>
                      </template>
                      <span class="text-capitalize"
                        >Service {{ row.product }} is removed.</span
                      >
                    </v-tooltip>
                  </template>
                </td>
                <td valign="top" width="10%" align="right">
                  <p
                    class="px-2 font-weight-600 text-left custom-grey-border py-1"
                  >
                    {{ formatNumber(row.quantity) }}
                  </p>
                </td>
                <td valign="top" width="10%" align="right">
                  <p
                    class="px-2 font-weight-600 text-left custom-grey-border py-1"
                  >
                    <template v-if="row.is_optional == 0">{{
                      formatMoney(row.rate)
                    }}</template>
                    <template v-else>{{ formatMoney() }}</template>
                  </p>
                </td>
                <td valign="top" width="10%" align="right">
                  <p
                    class="px-2 font-weight-600 text-left custom-grey-border py-1"
                  >
                    <template v-if="row.is_optional == 1">{{
                      formatMoney(row.rate)
                    }}</template>
                    <template v-else>{{ formatMoney() }}</template>
                  </p>
                </td>
                <td valign="top" width="10%" align="right" class="pr-4">
                  <p
                    class="px-2 font-weight-600 text-left custom-grey-border py-1"
                  >
                    {{ formatMoney(row.total) }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table
            v-if="
              quotation_detail.packages.length ||
              quotation_detail.services.length
            "
            width="100%"
            class="alternate-row"
            style="table-layout: fixed"
          >
            <tr>
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Sub Total</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.sub_total)
                }}</label>
              </td>
            </tr>
            <tr v-if="false && quotation_detail.quotation.optional_total">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Optional Sub Total</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.optional_total)
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.discount_amount">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Discount </label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.discount_amount)
                }}</label>
              </td>
            </tr>
            <tr v-if="false && quotation_detail.quotation.tax_amount">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0"
                  >Tax [{{
                    formatNumber(quotation_detail.quotation.tax_value)
                  }}%] (without discount)</label
                >
              </td>
              <td height="30px" align="center" width="15%">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer font-weight-600 m-0"
                      >{{
                        formatMoney(quotation_detail.quotation.sub_total)
                      }}</label
                    >
                  </template>
                  <span class="text-capitalize">Taxable Amount</span>
                </v-tooltip>
              </td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.tax_without_discount)
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.tax_amount">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0"
                  >Tax [{{
                    formatNumber(quotation_detail.quotation.tax_value)
                  }}%] (with discount)</label
                >
              </td>
              <td height="30px" align="center" width="15%">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer font-weight-600 m-0"
                      >{{
                        formatMoney(quotation_detail.quotation.taxable_amount)
                      }}</label
                    >
                  </template>
                  <span class="text-capitalize">Taxable Amount</span>
                </v-tooltip>
              </td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.tax_amount)
                }}</label>
              </td>
            </tr>
            <tr v-if="quotation_detail.quotation.adjustment">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0">Adjustment</label>
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.adjustment)
                }}</label>
              </td>
            </tr>
            <tr v-if="false">
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0"
                  >Grand Total (without discount)</label
                >
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.total_without_discount)
                }}</label>
              </td>
            </tr>
            <tr>
              <td height="30px" align="center" width="60%"></td>
              <td height="30px" align="right" width="15%">
                <label class="font-weight-500 m-0"
                  >Grand Total (with discount)</label
                >
              </td>
              <td height="30px" align="center" width="15%"></td>
              <td height="30px" align="right" width="10%" class="pr-4">
                <label class="font-weight-600 m-0">{{
                  formatMoney(quotation_detail.quotation.total)
                }}</label>
              </td>
            </tr>
          </table>
          <br />
          <table width="100%">
            <tbody>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  Notes:
                </td>
              </tr>
              <tr>
                <td colspan="10" align="left" valign="center">
                  <div
                    class="pre-formatted"
                    v-html="quotation_detail.quotation.client_remark"
                  ></div>
                </td>
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  Terms and Conditions:
                </td>
              </tr>
              <tr>
                <td colspan="10" align="left" valign="center">
                  <div
                    class="pre-formatted"
                    v-html="quotation_detail.quotation.term_conditions"
                  ></div>
                </td>
              </tr>
              <tr>
                <td height="30px" colspan="10" align="left" valign="center">
                  We trust above is in good order. Should you have any
                  clarifications, please do not hesitate to contact us.
                </td>
                <!-- Fixed Line For Every Quotation -->
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
            </tbody>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td
                  height="30px"
                  width="50%"
                  colspan="5"
                  align="left"
                  valign="center"
                >
                  Prepared by:
                </td>
                <td
                  height="30px"
                  width="50%"
                  colspan="5"
                  align="left"
                  valign="center"
                >
                  Customer Acceptance:
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  width="50%"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ company_detail.company_name }}
                </td>
                <td
                  colspan="5"
                  width="50%"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  <template v-if="quotation_detail.customer">
                    {{ quotation_detail.customer.company_name }}
                  </template>
                </td>
              </tr>
              <tr class="signature-pad" id="signature-pad">
                <td colspan="5" width="50%" align="left" valign="center">
                  <v-img
                    v-if="sales_signature"
                    contain
                    lazy-src="https://fakeimg.pl/542x140"
                    max-height="140"
                    max-width="542"
                    :src="sales_signature.sign_url"
                  ></v-img>
                </td>
                <td width="50%" colspan="5" align="left" valign="center">
                  <div
                    ref="customer_signature_div"
                    style="width: 100px"
                    class="custom-border-grey-dashed"
                  >
                    <canvas ref="customer_signature"></canvas>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  width="50%"
                  height="30px"
                  colspan="5"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ quotation_detail.quotation.sales }}
                </td>
                <td colspan="5" width="50%" align="left" valign="center">
                  Authorized Signature
                  <p class="float-right m-0">
                    <v-btn
                      content="Click here to clear signature"
                      v-tippy="{ arrow: true, placement: 'top' }"
                      icon
                      small
                      class="float-right"
                      v-on:click="customer_signature.clear()"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="5" width="50%" align="left" valign="center">
                  &nbsp;
                </td>
                <td colspan="5" width="50%" align="left" valign="center">
                  Date: {{ today_date }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="50px" align="right" valign="center">
                  <v-btn
                    :disabled="validSignature || formLoading || pageLoading"
                    :loading="formLoading"
                    class="mr-2 custom-grey-border custom-bold-button white--text"
                    color="cyan"
                    v-on:click="submitSignature"
                  >
                    Accept
                  </v-btn>
                  <v-btn
                    :disabled="formLoading || pageLoading"
                    :loading="formLoading"
                    class="ml-2 custom-grey-border custom-bold-button white--text"
                    color="red lighten-1"
                    v-on:click="rejectQuotationDialog = true"
                  >
                    Reject
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="false" width="100%">
            <tfoot>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="center"
                  valign="center"
                  class="custom-border-top"
                  style="font-size: 10px"
                >
                  {{ company_detail.street_1 }}, {{ company_detail.street_2 }},
                  {{ company_detail.landmark }}
                  {{ company_detail.zip_code }} Tel:
                  {{ company_detail.phone_number }} •
                  {{ company_detail.website_url }} •
                  {{ company_detail.email_address }}
                </td>
              </tr>
            </tfoot>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <Dialog :common-dialog="dialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyCustomer(false)"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model.trim="verificationCode"
                dense
                filled
                label="Security Code"
                solo
                flat
                :disabled="formLoading || pageLoading"
                :loading="formLoading"
                color="cyan"
                :rules="[
                  validateRules.required(verificationCode, 'Security Code'),
                  validateRules.minLength(verificationCode, 'Security Code', 4),
                  validateRules.maxLength(
                    verificationCode,
                    'Security Code',
                    10
                  ),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyCustomer(false)"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="rejectQuotationDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <div>
          <p class="m-0">Are you sure, you want to reject this package?</p>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="red lighten-1"
          v-on:click="rejectQuotation()"
        >
          Yes! Reject
        </v-btn>
        <v-btn
          :disabled="formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="rejectQuotationDialog = false"
        >
          No! Cancel
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>
<style type="text/css" scoped>
.quotation {
  font-weight: 600;
  text-decoration: underline;
}
.customer-font-bold {
  font-weight: 600;
}
.customer-border-top {
  border-top: solid 2px #000;
}
.customer-border-bottom {
  border-bottom: solid 2px #000;
}
.customer-border-right {
  border-right: solid 2px #000;
}
.customer-border-left {
  border-left: solid 2px #000;
}
.line-items td {
  padding: 4px;
}
table tbody td:first-child {
  width: 70px !important;
}
table tbody td:nth-child(3) {
  width: 100px !important;
}
table > tbody > tr.signature-pad > td {
  width: 50% !important;
}

.v-snack__content {
  font-size: 20px !important;
}

.quotation-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.quotation-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.quotation-approved.rejected h1 {
  color: #f74c4c;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.quotation-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.quotation-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved.rejected i {
  color: #f74c4c;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  PATCH,
  VERIFY_QUOTATION_CUSTOMER,
} from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import SignaturePad from "signature_pad";
import ObjectPath from "object-path";

export default {
  name: "quotation-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      dialog: true,
      formValid: true,
      formLoading: false,
      rejectQuotationDialog: false,
      isVerified: false,
      isSubmitted: false,
      isRejected: false,
      pageLoading: false,
      tokenNotFound: false,
      verificationCode: null,
      securityToken: null,
      today_date: null,
      customer_signature: null,
      validationErrorBag: new Array(),
      quotation_detail: new Object(),
      company_detail: new Object(),
      header_footer: new Object(),
      sales_signature: new Object(),
    };
  },
  components: {
    Dialog,
  },
  methods: {
    rejectQuotation() {
      this.formLoading = true;
      const quotation_id = ObjectPath.get(
        this.quotation_detail,
        "quotation.id",
        0
      );
      this.$store
        .dispatch(PATCH, {
          url: `quotation/${quotation_id}/reject?token=${this.securityToken}`,
        })
        .then(() => {
          this.isRejected = true;
          this.rejectQuotationDialog = false;
        })
        .catch((error) => {
          const errors = ObjectPath.get(error, "data.message", null);
          if (errors) {
            this.validationErrorBag = errors.split("<br>");
          } else {
            this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getSubTotalAmount(sub_total, discount) {
      return this.formatMoney(this.lodash.sum([sub_total, discount]));
    },
    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }

      _this.formLoading = true;
      _this.isSubmitted = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/customer/submit",
          data: {
            customer: _this.customer_signature.toDataURL(),
            code: _this.verificationCode,
          },
          token: _this.securityToken,
        })
        .then(() => {
          _this.isSubmitted = true;
        })
        .catch((error) => {
          const errors = ObjectPath.get(error, "response.data.message", null);
          if (errors) {
            _this.validationErrorBag = errors.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    verifyCustomer(ddirect) {
      const _this = this;

      if (!ddirect && !_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/verify",
          data: { code: _this.verificationCode },
          token: _this.securityToken,
        })
        .then((response) => {
          _this.quotation_detail = ObjectPath.get(response, "data.detail", {});
          _this.company_detail = ObjectPath.get(response, "data.company", {});
          _this.header_footer = ObjectPath.get(
            response,
            "data.header_footer.quotation_header",
            null
          );
          _this.sales_signature = ObjectPath.get(
            response,
            "data.sales_signature",
            null
          );
          _this.today_date = ObjectPath.get(response, "data.today_date", null);
          _this.dialog = false;
          _this.isVerified = true;
          _this.$nextTick(() => {
            _this.initSignature();
          });
        })
        .catch((error) => {
          const errors = ObjectPath.get(error, "response.data.message", null);
          if (errors) {
            _this.validationErrorBag = errors.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas);
      });
    },
  },
  mounted() {
    const security_code = ObjectPath.get(
      this.$route,
      "query.security-code",
      null
    );
    if (security_code) {
      this.verificationCode = security_code;
      this.verifyCustomer(true);
    }
  },
  created() {
    this.securityToken = ObjectPath.get(this.$route, "query.token", null);

    const main_body = document.getElementById("main-app-body-ace-interior");
    if (main_body) {
      main_body.removeAttribute("style");
    }

    if (!this.securityToken) {
      this.dialog = false;
      this.tokenNotFound = true;
    }
  },
  computed: {
    validSignature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
